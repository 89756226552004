// import logo from './logo.svg';
import { ethers } from "ethers";
import React from "react";
import './App.css';
import util_ReachMike from "./utils/ReachMike.json"

function App() {
  const [currAccount, setCurrentAccount] = React.useState("");
  const contractAddress = "0x18bd4C9c51d9F15252DBf203dD07CD62Ade0d881"
  const contractABI = util_ReachMike.abi;

  const [statusMessage, setStatusMessage] = React.useState("");
  const [newSupMessage, setNewSupMessage] = React.useState("");
  // setStatusMessage("Connect your ethereum wallet to say \"Sup?\"");

  const [allReaches, setAllReaches] = React.useState([]);
  async function getAllReaches(){
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const reachMikeContract = new ethers.Contract(contractAddress, contractABI, signer);
    let reaches = await reachMikeContract.getAllReaches() 

    let reachesCleaned = []
    reaches.forEach(reach => {
      reachesCleaned.push({
        address: reach.reacher,
        timestamp: new Date(reach.timestamp * 1000),
        message: reach.message
      })
    })
    reachesCleaned.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)
    setAllReaches(reachesCleaned)

    reachMikeContract.on("newSup", (from, timestamp, message) => {
      console.log("newSup", from, timestamp, message)
      setAllReaches(oldArray => [ {
        address: from,
        timestamp: new Date(timestamp * 1000),
        message: message
      }, ...oldArray] )
    })
  }

  const checkIfWalletIsConnected = () => {
    const { ethereum } = window;
    if(!ethereum){
      console.log("Install metamask or use an ethereum browser")
      // setStatusMessage("Your browser needs metamask or some ethereum plugin.")
      return
    } else {
      console.log("Ethereum object found: " + ethereum)
    }

    ethereum.request({ method: 'eth_accounts' })
    .then(accounts => {
      if(accounts.length !== 0) {
        const account = accounts[0];
        console.log("found authorized account: ", account)

        setCurrentAccount(account);
      } else {
        console.log("No authorized account found")
      }
    })

    if(allReaches.length === 0){
      console.log("found no reaches but connected so going to retrive them again")
      getAllReaches()
    }
  }

  const connectWallet = () => {
    const { ethereum } = window;
    if(!ethereum) {
      setStatusMessage("Your browser needs metamask or some ethereum plugin.")
      return
    }
    ethereum.request({ method: 'eth_requestAccounts' })
    .then(accounts => {
      console.log("Connected", accounts[0])
      setStatusMessage("Your browser is ready and you're known by your public key! Sup.")
      setCurrentAccount(accounts[0])
      getAllReaches();
    })
    .catch(err => console.log(err));
  }

  React.useEffect(() => {
    checkIfWalletIsConnected()
  }  )
  
  const reach = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const reachMikeContract = new ethers.Contract(contractAddress, contractABI, signer);

    let count = await reachMikeContract.getTotalReaches()
    console.log("Retrieved total reach count of ", count.toNumber())
    
    const supTxn = await reachMikeContract.sup(newSupMessage, { gasLimit: 300000 })
    console.log("Confirmation pending ... ", supTxn.hash)
    setStatusMessage("Your acknowledgement of Mike's existence is headed off to the mines. Please wait")
    await supTxn.wait();
    console.log("Mined and confirmed!", supTxn.hash)
    setStatusMessage("Your acknowledgement of Mike's existence is headed off to the mines. CONFIRMED!")
    setNewSupMessage("")

    count = await reachMikeContract.getTotalReaches()
    console.log("New reach count: ", count.toNumber())
    setStatusMessage("Thank you for acknowledging Mike's existence. This is acknowledgement number " + count.toNumber())
  }

  return (
    <div className="mainContainer">

      <div className="dataContainer">
        <div className="header">
          👋 Hello there!
        </div>

        <div className="bio">
          I am a robot that can get your ideas to Mike? 
        </div>

        <div className="cta">
          {statusMessage ? statusMessage : (
            <span>Connect your ethereum wallet to say "Sup?"</span>
            )}
        </div>
        
        {currAccount ? (
          <div className="cta">
            <input type="text" value={newSupMessage} onChange={e => {
              setNewSupMessage(e.target.value);
            }} />
            <br />
            <button className="reachButton" onClick={reach}>
              Reach out
            </button>
          </div>
          ) : (
          <div className="cta">
            <button className="reachButton" onClick={connectWallet}>
              Connect wallet
            </button>
          </div>
        )}
        
        {allReaches.map((reach, index) => {
          return (
            <div key={index} className="outReachCard">
              <div>Address: {reach.reacher}</div>
              <div>Time: {reach.timestamp.toString()}</div>
              <div>Message: {reach.message}</div>
            </div>
          )
        })
        }
      </div>

      <div className="footer">
        {process.env.REACT_APP_GIT_SHA}
      </div>
    </div>
  );
}

export default App;
